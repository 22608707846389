<template>
    <div class="managerial-furniture">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Muebles Gerenciales',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles gerenciales, más de 30 años fabricando escritorios gerenciales y muebles gerenciales en bucaramanga' },
			{ name: 'keywords', content:'escritorios bucaramanga, escritorios oficina bucaramanga, escritorios para oficina bucaramanga, escritorios gerenciales, escritorios gerenciales bucaramanga, escritorios para gerencia, escritorios para gerencia bucaramanga, escritorio en vidrio, escritorio en vidrio bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Muebles Gerenciales'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles gerenciales, más de 30 años fabricando escritorios gerenciales y muebles gerenciales en bucaramanga'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Muebles Gerenciales'},
			{name: 'twitter:description', content: 'Fabrica de muebles gerenciales, más de 30 años fabricando escritorios gerenciales y muebles gerenciales en bucaramanga' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Muebles Gerenciales'},
			{itemprop: 'description', content: 'Fabrica de muebles gerenciales, más de 30 años fabricando escritorios gerenciales y muebles gerenciales en bucaramanga' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Muebles gerenciales',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Mueble gerencial 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble gerencial 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_2.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble gerencial 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_3.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble gerencial 4',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_4.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_4.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble gerencial 5',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_5.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_5.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble gerencial 6',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/gerenciales/productos/gerencial_6.jpg',
                        slides: [
                            'mobiliario-de-oficina/gerenciales/productos/gerencial_6.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                ],
            }
        }
    }
}
</script>